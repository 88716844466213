import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import { useParams } from 'react-router-dom';
import appConfig from '../config/appConfig';
import { PSContext } from '../Contexts';
import displayConfig from '../config/displayConfig';
import { getSectionByConfig, getEntries } from '../utils/patientSummaryUtil';
import { SectionSelect } from '../components/core';
import SectionEmptyRow from '../components/EmptyRow';
import { GenericError } from '../model/error';
import { ErrorTypes } from '../constants';
import IDKeys from '../constants/idKeys';

/**
 * Display entry rows for the entries in the user's selected section.
 */
const SectionPage = () => {
  const { ps } = useContext(PSContext);
  const { sectionID } = useParams();

  // Use the section ID from the path to get the config for the selected section
  const sectionConfig = displayConfig[appConfig.spec].sections.find((section) => (sectionID === section.id));

  // If we can't locate the section config, the sectionID is invalid
  if (!sectionConfig) {
    throw new GenericError(ErrorTypes.PAGE_NOT_FOUND);
  }

  // Use the section code to get the entries for this section
  const section = getSectionByConfig(ps, sectionConfig);

  // If a section is found, get the entries, otherwise display 'No data available'
  const displayContent = section ? getEntries(ps, section, sectionConfig) : <SectionEmptyRow testID={`${IDKeys.SECTION_PAGE}EntryRow-0`} />;

  return (
    <Stack direction="column" gap={2} width="100%">
      <SectionSelect sectionID={sectionID} />
      <React.Fragment id={`${IDKeys.SECTION_PAGE}EntriesList`}>
        {displayContent}
      </React.Fragment>
    </Stack>
  );
};

export default SectionPage;
