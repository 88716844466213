import * as React from 'react';
import { Stack, TextField, Typography } from '@mui/material';
import { InfoRounded } from '@mui/icons-material';
import IDKeys from '../../constants/idKeys';

const PINInput = ({
  value, onChange, showValue = true, disabled = false, errorMessage = '', maxLength,
}) => {
  const handleInputEvent = (e) => onChange(e.target.value?.replaceAll(/[^0-9]/g, '').substring(0, maxLength) || '');

  return (
    <Stack>
      <TextField
        id={`${IDKeys.PIN_ENTRY}TextField`}
        autoFocus
        autoComplete="off"
        aria-label="pin-digit-input"
        disabled={disabled}
        type={showValue ? 'text' : 'password'}
        inputProps={{
          pattern: '[0-9]*',
          inputMode: 'numeric',
          maxLength,
        }}
        onChange={handleInputEvent}
        value={disabled ? '' : value}
        error={errorMessage?.length > 0}
        sx={{
          width: '300px',
        }}
      />
      <Stack direction="row" justifyContent="space-between">
        {/* Error message if exists */}
        <Stack direction="row" spacing={1} alignItems="center" color="error.main">
          {errorMessage && (
            <>
              <InfoRounded fontSize="18px" />
              <Typography id={`${IDKeys.PIN_ENTRY}ErrorText`} variant="body_sm">
                {errorMessage}
              </Typography>
            </>
          )}
        </Stack>
        {/* Input counter */}
        <Typography id={`${IDKeys.PIN_ENTRY}PINLengthText`} component="span" alignSelf="flex-end" sx={{ color: 'text.secondary' }}>
          {maxLength && `${value.length}/${maxLength}`}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default PINInput;
