import { Stack, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import IDKeys from '../constants/idKeys';
import { ReactComponent as VeroSourceLogo } from '../img/verosource-logo-grey.svg';

const PageFooter = ({ children }) => {
  const [translate] = useTranslation();

  return (
    <Stack
      sx={{
        alignItems: 'center',
        paddingX: 2,
        paddingBottom: 2,
        gap: 2,
      }}
    >
      <React.Fragment id={`${IDKeys.FOOTER}Content`}>
        {children}
        <Typography variant="h_xxs" textAlign="center">{translate('closeBrowser')}</Typography>
      </React.Fragment>
      <VeroSourceLogo id={`${IDKeys.FOOTER}VeroSourceLogo`} width="110px" height="33px" />
    </Stack>
  );
};

export default PageFooter;
