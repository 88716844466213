import React, { useContext } from 'react';
import { Stack } from '@mui/material';
import appConfig from '../config/appConfig';
import JSONCard from '../components/JSONCard';
import SectionRow from '../components/SectionRow';
import displayConfig from '../config/displayConfig';
import { PSContext } from '../Contexts';
import IDKeys from '../constants/idKeys';

/**
 * Display section rows for the Patient Summary sections.
 */
const LandingPage = () => {
  const { ps } = useContext(PSContext);
  const supportedSections = displayConfig[appConfig.spec].sections;
  return (
    <Stack
      id={`${IDKeys.LANDING_PAGE}SectionsList`}
      direction="column"
      gap={2}
      sx={{ width: '100%' }}
    >
      {supportedSections.map((section, i) => (
        <SectionRow
          testID={`${IDKeys.LANDING_PAGE}SectionRow-${i}`}
          key={section.id}
          section={section}
        />
      ))}
      {appConfig.displayJSONBlob && <JSONCard id={`${IDKeys.LANDING_PAGE}JSONCard`} ps={ps} />}
    </Stack>
  );
};

export default LandingPage;
