import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Card,
  CardActionArea,
  CardContent,
  Stack,
  Typography,
  Divider,
} from '@mui/material';
import { ChevronRight } from '@mui/icons-material';
import { formatDateAsParts } from '../utils/dateUtil';
import EntryDetailsModal from './EntryDetailsModal';
import { getByDisplayKey } from '../utils/displayKeyUtil';

/**
 * Row for a section entry. When clicked, open a modal containing details about the entry.
 */
const SectionEntryRow = ({ testID, entry, entryConfig }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [, i18n] = useTranslation();

  const title = getByDisplayKey(entry, entryConfig.titleKey);
  const subtitle = getByDisplayKey(entry, entryConfig.subtitleKey);

  const date = getByDisplayKey(entry, entryConfig.dateKey);
  const [year, month, day] = formatDateAsParts(date, i18n.language);

  const dayMonth = i18n.language === 'fr' ? `${day} ${month}` : `${month} ${day}`;

  return (
    <>
      <Card id={testID} sx={{ width: '100%', borderRadius: 2, boxShadow: 0 }}>
        <CardActionArea id={`${testID}-ActionArea`} onClick={() => setModalOpen(true)}>
          <CardContent sx={{ padding: 2 }}>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
            >
              <Stack
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                width="100%"
              >
                <Stack alignItems="center" sx={{ color: 'primary.dark', flexBasis: '40px', flexShrink: 0 }}>
                  <Typography
                    id={`${testID}-DayMonthText`}
                    variant="body_xs"
                    sx={{ p: 0, fontWeight: 700 }}
                    noWrap
                  >
                    {dayMonth}
                  </Typography>
                  <Typography
                    id={`${testID}-YearText`}
                    variant="body_xs"
                    sx={{ p: 0 }}
                    noWrap
                  >
                    {year}
                  </Typography>
                </Stack>
                <Divider orientation="vertical" flexItem sx={{ mx: 2 }} />
                <Stack>
                  <Typography id={`${testID}-TitleText`} variant="body_md">{title}</Typography>
                  {subtitle && <Typography id={`${testID}-SubtitleText`} variant="body_xs" sx={{ color: 'text.secondary' }}>{subtitle}</Typography>}
                </Stack>
              </Stack>
              <ChevronRight fontSize="large" />
            </Stack>
          </CardContent>
        </CardActionArea>
      </Card>
      <EntryDetailsModal
        testID={`${testID}-Details`}
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        entry={entry}
        entryConfig={entryConfig}
      />
    </>
  );
};

export default SectionEntryRow;
