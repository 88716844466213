import { useTranslation } from 'react-i18next';
import {
  Card,
  Stack,
  Typography,
} from '@mui/material';

/**
 * Row placeholder for a section without information.
 */
const SectionEmptyRow = ({ testID }) => {
  const [translate] = useTranslation();

  return (
    <Card
      id={testID}
      sx={{
        width: '100%', borderRadius: 2, boxShadow: 0, px: 0, py: 5,
      }}
    >
      <Stack
        justifyContent="center"
        alignItems="center"
      >
        <Typography id={`${testID}-TitleText`} variant="body_lg">{translate('noDataAvailable')}</Typography>
      </Stack>
    </Card>
  );
};

export default SectionEmptyRow;
