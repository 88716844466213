/**
 * @file Prefixes to use for idKeys when assigning unique identifiers to elements for automated QA.
 */

/**
 * Prefixes for pages and regions.
 */
const IDKeys = {
  HEADER: 'header',
  FOOTER: 'footer',
  DISCLAIMER: 'disclaimer',
  PATIENT_CARD: 'patientCard',
  PIN_ENTRY: 'PINEntry',
  LANDING_PAGE: 'landingPage',
  SECTION_PAGE: 'sectionPage',
  SECTION_SELECT: 'sectionSelect',
  JSON_CARD: 'JSONCard',
  ERROR_PAGE: 'errorPage',
};

export default IDKeys;
